import AbstractController from "@/controllers/abstracts/AbstractController";
import { Component } from "vue-property-decorator";
import { State } from "vuex-class";
import { IProposalModel } from "@/types/models/Proposal";

interface ICode {
  code: string | number;
}
interface IFlowOrder extends ICode {
  component?: string;
}

@Component({})
export default class AbstractFlowController extends AbstractController {
  @State((state) => state.Proposal.proposal) proposal!: IProposalModel;

  flowOrder: IFlowOrder[] = [];
  activeCode: string | number = "";

  async executeTasks() {
    return true;
  }

  _setFlowOrder(value: IFlowOrder[] = []) {
    this.flowOrder = value;
  }

  get currentFlowComponent() {
    if (!this.flowOrder.length) return { code: "", component: "" };
    return this.flowOrder.find((component) => component?.code === this.activeCode);
  }

  _findFlowPosition({ code }: ICode) {
    return this.flowOrder.findIndex((item) => item.code === code);
  }

  handleComponentFlow() {
    if (
      this._shouldChangeComponent({
        position: this.flowPosition,
      })
    ) {
      this._setNextComponent();
      return false;
    } else {
      return true;
    }
  }

  removeComponentFromFlow({ code }: ICode) {
    const position = this._findFlowPosition({ code });
    this.flowOrder.splice(position, 1);
  }

  _setNextComponent() {
    this.activeCode = this.flowOrder[this.flowPosition + 1]?.code || "";
  }

  get flowPosition() {
    return this._findFlowPosition({ code: this.activeCode });
  }

  get shouldDisplayBackButton() {
    if (!this.activeCode) return false;
    return !!this.flowPosition;
  }

  _setPreviousGroup() {
    this.$emit("findPreviousComponent");
  }

  setPreviousComponent() {
    const position = this.flowPosition;
    if (!position || position === -1) return this._setPreviousGroup();
    this.activeCode = this.flowOrder[position - 1].code;
  }

  _shouldChangeComponent({ position }: { position: number }) {
    return position !== this.flowOrder.length - 1;
  }
}
